import './about.css'
import './animations.css'
import React, { useRef } from 'react';
import useIntersection from './useIntersection'

const About = () => {
  const ref = useRef();
  const inViewport = useIntersection(ref, '0px');

  let className = 'about-container';
  if (inViewport) {
    className += ' aboutAnimations';
  }

  return (
    <div ref={ref} className="dis">
      <div className={className}>
          <div className='about-text-container'>
              <div className='about-large-text'>
                  <h1>Techie</h1>
                  <h1>Outdoorsy</h1>
                  <h1>Entrepreneurial</h1>
              </div>
          </div>
          <div className='about-paragraph-container'>
              <h3 className='about-paragraph-text'>I have experience in a broad range of technical and soft skills including programming, customer service/sales, problem solving, finance, data analysis, and project management. These skills have helped me excel in various roles and consistently deliver exceptional results.</h3>
              <h3 className='about-paragraph-text'>In my free time, I am passionate about skiing, riding my motorcycle, surfing, and exploring new places and cultures through travel. I also enjoy relaxing at home and watching movies with friends and family. These activities allow me to stay active and engaged, and help me to lead a balanced and fulfilling life.</h3>
              <h3 className='about-paragraph-text'>I am also deeply interested in the world of startups, entrepreneurship, and emerging technologies such as cryptocurrency, blockchain, and web<span className='numbers'>3</span>. I am constantly seeking out new opportunities to learn and grow in these areas, and am always on the lookout for innovative ways to apply my skills and knowledge. If you feel the same, please reach out!</h3>
          </div>
      </div>
    </div>
  )
}

export default About