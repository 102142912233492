import logo from '../Images/Bret.png';
import github from '../Images/github.png';
import linkedin from '../Images/linkedin.png';
import twitter from '../Images/twitter.png';
import './header.css'

const Header = () => {
  return (
    <nav>
        <div className='header-container'>
            <div className='div-logo'>
                <img src={logo} className='logo' alt="Logo"/>
            </div>
            <div className='div-socials'>
              <a href="https://www.linkedin.com/in/bret-shilliday/" target="_blank" rel="noreferrer">
                <img src={linkedin} className='socials' alt="LinkedIn"/>
              </a>
              <a href="https://twitter.com/BretShilliday" target="_blank" rel="noreferrer">
                <img src={twitter} className='socials' alt="Twitter"/>
              </a>
              <a href="https://github.com/bretshilliday" target="_blank" rel="noreferrer">
                <img src={github} className='socials' alt="Github"/>
              </a>
            </div>
        </div>
    </nav>
  
  )
}

export default Header