import secretSanta from '../Images/SecretSanta.png';
import HaskayneNFT from '../Images/HaskayneNFT.png';

export const SliderData = [
    {
      image: secretSanta,
      url: "https://github.com/kathrynl13/SENG513_GroupProject",
      alt: "Secret Santa"
    },
    {
      image: HaskayneNFT,
      url: "https://opensea.io/collection/haskaynedefifall2022",
      alt: "Haskayne NFT Collection"
    },
    {
      image: secretSanta,
      url: "https://github.com/kathrynl13/SENG513_GroupProject",
      alt: "Secret Santa"
    }
  ];