import React, { useState } from 'react';
import { SliderData } from './SliderData';
// import twitter from '../Images/twitter.png';
import './imageslider.css'
// import { FaArrowAltCircleRight, FaArrowAltCircleLeft } from 'react-icons/fa';

const ImageSlider = ({ slides }) => {
  document.addEventListener(
    "mousemove",
    (ev) => {
      const f = document.getElementById("mousePal");
      f.style.transform = `translateY(${ev.pageY - 50}px)`;
      f.style.transform += `translateX(${ev.pageX - 50}px)`;
    },
    false
  );

  const [current, setCurrent] = useState(0);
  const length = slides.length;

  const nextSlide = () => {
    setCurrent(current === length - 1 ? 0 : current + 1);
  };

  const prevSlide = () => {
    setCurrent(current === 0 ? length - 1 : current - 1);
  };

  if (!Array.isArray(slides) || slides.length <= 0) {
    return null;
  }

  return (
    <section className='projects-section'>
      <h1 className="projects">Projects</h1>
      <div className='slider'>
        <i className='left-arrow' onClick={prevSlide} />
        {SliderData.map((slide, index) => {
          return (
            <div
              className={index === current ? 'slide active' : 'slide'}
              key={index}
            > <a href={slide.url} target="_blank" rel="noreferrer">
              {index === current && (
                  <img src={slide.image} alt={slide.alt} className='sliderimage' />
              )}
              </a>
            </div>
          );
        })}
        <i className='right-arrow' onClick={nextSlide} />
      </div>
      <p id="mousePal" className="ball">Hey 👋🏻</p>
    </section>
  );
};

export default ImageSlider;