import welcomeImage from '../Images/BretStanding.png';
import './welcome.css'
import './animations.css'

const Welcome = () => {
  return (
    <div className='welcome-container'>
        <div className='welcome-text-container'>
            <div className='welcome-large-text'>
                <h1>Hello,</h1>
                <h1>I'm <span className='bret-text bretAnimations'>Bret</span>.</h1>
            </div>
            {/* <div className='welcome-small-text'>
                <h3 className='welcome-small-text-h3'>A recent graduate with a passion for programming and fintech. I am interested in the intersection of technology and finance and enjoy exploring ways these fields can create innovative solutions and drive change.</h3>
            </div> */}
        </div>
        <div className='welcome-image-container'>
            <img src={welcomeImage} alt="Bret" className='welcome-image'/>
        </div>
    </div>
  )
}

export default Welcome