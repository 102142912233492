import { useState, useEffect } from 'react'

export default function useIntersection(element, rootMargin) {
    const [isVisible, setState] = useState(false);

    useEffect(() => {
        setTimeout(() => {
        const observer = new IntersectionObserver(
            ([entry]) => {
                if (entry.isIntersecting) {
                    setState(entry.isIntersecting);
                    observer.unobserve(element.current);
                }
            }, { rootMargin }
        );

        element.current && observer.observe(element.current);

        return () => observer.unobserve(element.current);

    } , 100);
    }, []);

    return isVisible;
};

// setTimeout(() => {

// } , 1000);