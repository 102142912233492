
import Header from "./components/Header";
import Welcome from "./components/Welcome";
import About from "./components/About";
import Space from "./components/Space";
import ImageSlider from "./components/ImageSlider";
import { SliderData } from "./components/SliderData";
import './App.css'

function App() {
  return (
    <div className="App">
      <Header/>
      <Welcome/>
      <Space/>
      <About/>
      <Space/>
      <ImageSlider slides={SliderData}/>
      <Space/>
      <Space/>
    </div>
  );
}

export default App;
